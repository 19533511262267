<template>
  <Modal
    v-if="!isShowOptionModal"
    size="medium"
    :title="t('video.subtitle.modal.add.title')"
    :primaryButtonText="t('common:button.next')"
    :secondaryButtonText="t('common:button.cancel')"
    :onClickPrimaryButton="onSelectOption"
    :onClickSecondaryButton="props.onClose"
    :isDisabledPrimaryButton="isDisabledPrimaryButton"
    @modalClose="props.onClose"
  >
    <template v-slot:body>
      <Field :title="$t('video.subtitle.modal.add.choose')">
        <RadioButtonGroup
          :options="addingCaptionOptions"
          :modelValue="selectedOption"
          default-value="upload"
          :onChange="onOptionChange"
        />
      </Field>
    </template>
  </Modal>
  <UploadVideoCaptionModal
    v-if="isShowOptionModal && selectedOption === 'upload'"
    :onClose="props.onClose"
    @to-init="backtoFirstStep"
  />
  <AutoGenerateCaptionModal
    v-if="isShowOptionModal && selectedOption === 'auto-gen'"
    :onClose="props.onClose"
    @to-init="backtoFirstStep"
  />
  <TranslateVideoCaptionModal
    v-if="isShowOptionModal && selectedOption === 'translate'"
    :onClose="props.onClose"
    @to-init="backtoFirstStep"
  />
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import AutoGenerateCaptionModal from '@/modules/captionProfile/components/organisms/autoGenerateCaptionModal/AutoGenerateCaptionModal.vue';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import TranslateVideoCaptionModal from '../translateVideoCaptionModal/TranslateVideoCaptionModal.vue';
import UploadVideoCaptionModal from '../uploadVideoCaptionModal/UploadVideoCaptionModal.vue';
import { useStore } from '../../../store/hooks';

const { t } = useI18n();
const store = useStore();

const props = defineProps({
  onClose: {
    type: Function,
    default: () => {},
    required: true,
  },
  prefilledOption: {
    type: String,
    default: null,
  },
});

const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures || {});
const currentVideo = computed(() => store.state.video.currentVideo || {});

const isProcessingCompleted = computed(() => currentVideo?.value?.vod?.processingInfo?.state === 'completed');
const audioTracks = computed(() => store.state.video.currentVideo.vod.outputs?.filter((output) => output?.type === 'audio'));

const hasNonNullAudioTracksBitRate = computed(() => {
  //  After video transcode, Bitrate of audio tracks wil wait for updated
  // Bitrate is required for next step
  if (audioTracks.value && audioTracks?.value?.length > 1) {
    return audioTracks?.value.every(((track) => track.bitRate !== null));
  }
  // single audio tracks
  return true;
});

/* eslint arrow-body-style: "off" */
const addingCaptionOptions = computed(() => {
  return [
    {
      label: t('video.subtitle.modal.add.upload.title'),
      description: t('video.subtitle.modal.add.upload.description'),
      value: 'upload',
      disabled: false,
    },
    {
      label: t('video.subtitle.modal.add.typeManually.title'),
      description: t('video.subtitle.modal.add.typeManually.description'),
      value: 'type',
      disabled: false,
    },
    {
      label: t('video.subtitle.modal.add.autoGenerate.title'),
      description: t('video.subtitle.modal.add.autoGenerate.description'),
      value: 'auto-gen',
      disabled: !teamFeatures.value['auto-generate-caption'] || !(isProcessingCompleted?.value) || !(hasNonNullAudioTracksBitRate.value),
      warning: !(isProcessingCompleted?.value) && 'This operation will be available after complete transcode.',
    },
    {
      label: t('video.subtitle.modal.add.translate.title'),
      description: t('video.subtitle.modal.add.translate.description'),
      value: 'translate',
      disabled: !teamFeatures.value['auto-generate-caption'],
    },
  ];
});

const selectedOption = ref(props.prefilledOption ?? 'upload');
const onOptionChange = (value) => {
  selectedOption.value = value;
};

// second step, show modal of the selected method
const isShowOptionModal = ref(false);

const onSelectOption = () => {
  isShowOptionModal.value = true;
};

const isDisabledPrimaryButton = computed(() => selectedOption.value === 'auto-gen' && !isProcessingCompleted.value);

const backtoFirstStep = () => {
  isShowOptionModal.value = false;
};
</script>

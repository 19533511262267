import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = {
  key: 0,
  class: "title-container"
}
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = {
  key: 1,
  class: "subtitle"
}
const _hoisted_5 = {
  key: 1,
  class: "action-container"
}
const _hoisted_6 = {
  key: 0,
  class: "search-input-container"
}
const _hoisted_7 = { class: "table-action-container" }
const _hoisted_8 = {
  key: 2,
  class: "table-tab-container"
}
const _hoisted_9 = {
  key: 3,
  class: "selected-container"
}
const _hoisted_10 = {
  key: 5,
  class: "table-footer-container"
}

import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

interface DataTableProps {
  title?: string;
  subtitle?: string;
  searchInputPlaceholder?: string;
  searchInputValue?: string;
  isSearchInput?: boolean;
  onSearchData?: (event: KeyboardEvent) => void;
  responsiveLayout?: 'a' | 'b' | 'c' | 'draggable';
  selectedItemsCount?: number;
  actionButtonText?: string;
  actionButtonIconClassName?: string;
  dataTest?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DataTable',
  props: {
    title: {},
    subtitle: {},
    searchInputPlaceholder: {},
    searchInputValue: { default: '' },
    isSearchInput: { type: Boolean, default: true },
    onSearchData: {},
    responsiveLayout: {},
    selectedItemsCount: {},
    actionButtonText: {},
    actionButtonIconClassName: {},
    dataTest: {}
  },
  emits: ["select-action"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  title,
  subtitle,
  searchInputPlaceholder,
  searchInputValue,
  isSearchInput,
  onSearchData,
  responsiveLayout,
  selectedItemsCount,
  actionButtonText,
  actionButtonIconClassName,
  dataTest,
} = toRefs(props);

const emit = __emit;

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "data-table-container",
    "data-test": _unref(dataTest)
  }, [
    (_unref(title) || _unref(subtitle))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_unref(title))
            ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_unref(title)), 1))
            : _createCommentVNode("", true),
          (_unref(subtitle))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(subtitle)), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(onSearchData) || _ctx.$slots['table-action'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_unref(onSearchData))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(Input, {
                  dataTest: "input-data-table",
                  onChange: _unref(onSearchData),
                  onPressedEnter: _unref(onSearchData),
                  isSearchInput: _unref(isSearchInput),
                  modelValue: _unref(searchInputValue),
                  placeholder: _unref(searchInputPlaceholder) || _unref(t)('common:form.searchBy')
                }, null, 8, ["onChange", "onPressedEnter", "isSearchInput", "modelValue", "placeholder"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "table-action")
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots['table-tab'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "table-tab")
        ]))
      : _createCommentVNode("", true),
    (_unref(selectedItemsCount))
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("p", null, _toDisplayString(_unref(selectedItemsCount)) + " selected", 1),
          _createVNode(Button, {
            buttonStyle: "secondary",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (emit('select-action', true)), ["stop"]))
          }, {
            "icon-prefix": _withCtx(() => [
              _createElementVNode("i", {
                class: _normalizeClass(_unref(actionButtonIconClassName))
              }, null, 2)
            ]),
            content: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(actionButtonText)), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots['table-header'] || _ctx.$slots['table-content'] || _ctx.$slots['table-footer'])
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["table", _unref(responsiveLayout) ? `responsive-layout-${_unref(responsiveLayout)}` : ''])
        }, [
          (_ctx.$slots['table-header'])
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["table-header", _unref(responsiveLayout) ? `responsive-layout-${_unref(responsiveLayout)}` : ''])
              }, [
                _renderSlot(_ctx.$slots, "table-header")
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.$slots['table-content'])
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["table-content", _unref(responsiveLayout) ? `responsive-layout-${_unref(responsiveLayout)}` : ''])
              }, [
                _renderSlot(_ctx.$slots, "table-content")
              ], 2))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.$slots['table-footer'])
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _renderSlot(_ctx.$slots, "table-footer")
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
}

})
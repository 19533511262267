<template>
  <Modal
    size="medium"
    :title="t('video.subtitle.modal.autoGenerate.title')"
    :primaryButtonText="showSubtitleEmptyButtonText ? t('video.subtitle.modal.add.empty.button') : primaryButtonText"
    :onClickPrimaryButton="onClickPrimaryButton"
    :isDisabledPrimaryButton="isDisabledPrimaryButton"
    :secondaryButtonText="t('common:button.back')"
    :onClickSecondaryButton="onClickSecondaryButton"
    :isSaving="createCaptionDraftState.state === 'saving'"
    @modalClose="onClose"
  >
    <template v-slot:body>
      <div v-if="pageName[pageIndex] === 'audio'" class="body-subtitle-profile">
        <RadioButtonGroup
          :options="audioTrackOptions"
          :modelValue="selectedAudioTrack"
          :onChange="(value) => selectedAudioTrack = value"
        />
      </div>
      <div v-if="pageName[pageIndex] === 'profile'" class="body-subtitle-profile-autogenerate">
        <template v-if="loadCaptionProfileListState.status === 'loading'">
          <PreloaderBar width="600px" height="132px" />
        </template>
        <template v-else-if="captionProfileList.length > 0">
          <Field title="Select a subtitle profile" class="profile-selector">
            <AutogenCaptionProfileSelector
              :modelValue="selectedProfileOption"
              @update:modelValue="onSelectProfile"
            />
          </Field>
          <div class="create-profile">
            {{ t('video.subtitle.modal.autoGenerate.noProfile') }}
            <router-link :to="`/${teamName}/manage/subtitle-profiles`">{{ t('video.subtitleProfile.create.title') }}</router-link>
          </div>
        </template>
        <template v-else>
          {{ t('video.subtitle.modal.add.empty.text') }}
          <router-link :to="`/${teamName}/manage/subtitle-profiles`">{{ t('video.subtitleProfile.create.title') }}</router-link>
        </template>
      </div>
      <div v-if="pageName[pageIndex] === 'status'" class="body-subtitle-profile">
        <CaptionStatusRadioGroup
          :modelValue="selectedStatusOption"
          :onChange="onStatusOptionChange"
        />
      </div>
    </template>
    <template v-slot:primary-btn-suffix v-if="pageIndex !== lastPageIndex">
      <i class="fas fa-chevron-right"></i>
    </template>
    <template v-slot:secondary-btn-prefix>
      <i class="fas fa-chevron-left"></i>
    </template>
    <template v-slot:footer>
      <Button @click="onClose" type="button" buttonStyle="text-secondary" v-if="createCaptionDraftState.state !== 'error'">
        {{ t('common:button.cancel') }}
      </Button>
      <div class="error-message" v-if="createCaptionDraftState.state === 'error'">
        {{createCaptionDraftState.error?.message}}
      </div>
    </template>
  </Modal>
</template>

<script setup>
/* eslint-disable */
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { createCaptionDraft } from '@/modules/videoCaption/services';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import RadioButtonGroup from '@/modules/shared/components/molecules/radioButtonGroup/RadioButtonGroup.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import AutogenCaptionProfileSelector from '@/modules/captionProfile/components/molecules/autogenCaptionProfileSelector/AutogenCaptionProfileSelector.vue';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import CaptionStatusRadioGroup from '@/modules/captionProfile/components/molecules/captionStatusRadioGroup/CaptionStatusRadioGroup.vue';
import PreloaderBar from '@/modules/shared/components/atoms/preloaderBar/PreloaderBar.vue';
import { setStateSavingStatusByType } from '@/modules/shared/utils/stateManagement';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import { useStore } from '../../../store/hooks';

const props = defineProps({
  onClose: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['toInit']);

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();

const createCaptionDraftState = ref(setStateSavingStatusByType());
const selectedProfileOption = ref(null);
const selectedStatusOption = ref('draft');

const { videoKey, teamName } = route.params;
const filter = computed(() => route.query);

const captionProfileList = computed(() => store.state.captionProfileList?.captionProfileList ?? []);
const loadCaptionProfileListState = computed(() => store.state.captionProfileList?.loadCaptionProfileListState);

const revisionKey = computed(() => store.state.video.currentVideo.vod.revisionKey);
const audioTracks = computed(() => store.state.video.currentVideo.vod.outputs?.filter((output) => output?.type === 'audio'));

const maxBirateAudioTrackInLangCode = computed(() => {
  // use array reduce func to find max then use Object.values to map value to array
  const result = Object.values(audioTracks.value.reduce((acc, current) => {
    const { languageCode, bitRate } = current;
    if (!acc[languageCode] || bitRate > acc[languageCode].bitRate) {
      acc[languageCode] = current;
    }
    return acc;
  }, {}));
  return result;
});

const audioTrackOptions = computed(() => maxBirateAudioTrackInLangCode?.value?.map((track) => ({
  label: track?.name,
  value: track,
  subLabel: track?.languageCode,
})));

const pageName = computed(() => (audioTracks?.value?.length > 0 ? ['audio', 'profile', 'status'] : ['profile', 'status']));
const pageIndex = ref(0);
const lastPageIndex = computed(() => pageName?.value?.length - 1);
const showSubtitleEmptyButtonText = computed(() => captionProfileList?.value?.length === 0 && pageName.value[pageIndex.value] === 'profile');

const selectedAudioTrack = ref();

// caption profile selector
const onSelectProfile = (value) => {
  selectedProfileOption.value = value;
};

// caption status
const onStatusOptionChange = (value) => {
  selectedStatusOption.value = value;
};

const onSubmit = async () => {
  try {
    createCaptionDraftState.value = setStateSavingStatusByType({ type: 'saving' });
    const sharedFields = {
      languageCode: selectedProfileOption.value.transcribe.language.code,
    };
    const captionDrafts = selectedProfileOption.value.transcribe.services.map((service) => ({
      ...sharedFields,
      captionProcessingType: {
        type: 'transcribe',
        service,
      },
    }));

    const payload = {
      autoGenerateCaptionSettingId: selectedProfileOption.value.id,
      publishStatusSetting: selectedStatusOption.value,
      ...(selectedAudioTrack.value && { audioVariantKey: selectedAudioTrack?.value?.audioVariantKey }),
      captionDrafts,
    };

    const response = await createCaptionDraft(videoKey, revisionKey.value, payload);
    if (response.status === 200) {
      // reload
      await store.dispatch('reloadCaptionList', {
        filter: filter.value,
      });

      // go to last page if not
      const { lastPage } = store.state.videoCaption;
      if (Number(filter.value.page) !== Number(lastPage)) {
        // console.log('change', filter.value.page, lastPage);
        router.push({ path: route.path, query: { page: lastPage } });
      }
      props.onClose();
    }
    createCaptionDraftState.value = setStateSavingStatusByType({ type: 'success' });
  } catch (err) {
    createCaptionDraftState.value = setStateSavingStatusByType({ type: 'error', error: formatErrorObject(err, 'Caption') });
  }
};

// modal page change state
const onNextPage = () => {
  if (pageIndex.value !== lastPageIndex.value) {
    pageIndex.value += 1;
  }
};
const onBack = () => {
  pageIndex.value -= 1;
};

const onClickPrimaryButton = () => {
  if (pageIndex.value === lastPageIndex.value) {
    onSubmit();
  } else {
    onNextPage();
  }
};
const onClickSecondaryButton = () => {
  if (pageIndex.value === 0) {
    emit('toInit');
  } else {
    onBack();
  }
};

onMounted(() => {
  store.dispatch('loadCaptionProfileList');
});

const isDisabledPrimaryButton = computed(() => {
  if ((pageName.value)[pageIndex.value] === 'audio') {
    return !selectedAudioTrack.value;
  }

  return !selectedProfileOption.value;

});

const primaryButtonText = computed(() => {
  if (pageIndex.value === lastPageIndex.value) {
    return t('video.subtitle.generate');
  }
  return t('common:button.next');
});

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.heading-text {
  margin-bottom: 24px;
}

.body-subtitle-profile-autogenerate {
  overflow-x: hidden;

  .create-profile {
    margin-top: 32px;
    color: $grey-800;
  }
}

</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field-container" }

import FormLabel from '@/modules/shared/components/molecules/formLabel/FormLabel.vue';
import FormErrorMessage from '@/modules/shared/components/atoms/formErrorMessage/FormErrorMessage.vue';
import FormHelpText from '@/modules/shared/components/atoms/formHelpText/FormHelpText.vue';
import { toRefs } from 'vue';

interface FieldProps {
  isShowErrorMessage?: boolean;
  errorMessage?: string;
  title?: string;
  subtitle?: string;
  helpText?: string;
  isHelpTextHtml?: boolean;
  min?: string;
  max?: string;
  characterCount?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Field',
  props: {
    isShowErrorMessage: { type: Boolean, default: false },
    errorMessage: {},
    title: {},
    subtitle: {},
    helpText: {},
    isHelpTextHtml: { type: Boolean, default: false },
    min: {},
    max: {},
    characterCount: {}
  },
  setup(__props: any) {

const props = __props;
const {
  isShowErrorMessage,
  errorMessage,
  title,
  subtitle,
  helpText,
  isHelpTextHtml,
  min,
  max,
  characterCount,
} = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FormLabel, {
      class: "form-label",
      title: _unref(title),
      subtitle: _unref(subtitle),
      characterCount: _unref(characterCount),
      max: _unref(max),
      min: _unref(min)
    }, null, 8, ["title", "subtitle", "characterCount", "max", "min"]),
    _renderSlot(_ctx.$slots, "default"),
    (_unref(errorMessage) && _unref(isShowErrorMessage))
      ? (_openBlock(), _createBlock(FormErrorMessage, {
          key: 0,
          errorMessage: _unref(errorMessage)
        }, null, 8, ["errorMessage"]))
      : _createCommentVNode("", true),
    (_unref(helpText) && !(_unref(errorMessage) && _unref(isShowErrorMessage)))
      ? (_openBlock(), _createBlock(FormHelpText, {
          key: 1,
          helpText: _unref(helpText),
          isHtml: _unref(isHelpTextHtml)
        }, null, 8, ["helpText", "isHtml"]))
      : _createCommentVNode("", true)
  ]))
}
}

})
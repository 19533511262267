import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, renderList as _renderList, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "search-container" }
const _hoisted_2 = { class: "search-input-wrapper" }
const _hoisted_3 = { class: "form-control-search" }
const _hoisted_4 = { class: "button-text" }
const _hoisted_5 = { class: "video-item-container video-list-mobile" }
const _hoisted_6 = {
  key: 0,
  class: "pagination-container"
}

import SuccessToast from '@/modules/shared/components/molecules/toasts/SuccessToast.vue';
import { Filter } from '@/types/Axios.type';
import _ from 'lodash';
import {
  computed, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import Pagination from '@/modules/shared/components/atoms/pagination/Pagination.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import FilterSlideOut from '@/modules/shared/components/organisms/filterSlideOut/FilterSlideOut.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import ChooseProjectForUploadModal from '@/modules/projectVideoList/components/organisms/chooseProjectForUploadModal/ChooseProjectForUploadModal.vue';
import VideoItem from '../components/molecules/videoItem/VideoItem.vue';
import { useStore } from '../store/hooks';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectVideos',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const columnType = ref('created');
const isSlideOutToggle = ref(false);

const currentProject = computed(() => store.state.project.currentProject);
const areProjectVideosMoved = computed(() => store.state.global.areProjectVideosMoved);
const projectVideoList = computed(() => store.state.projectVideoList.projectVideoList);
const projectVideoListLastPage = computed(() => store.state.projectVideoList.projectVideoListLastPage);
const loadProjectVideoListState = computed(() => store.state.projectVideoList.loadProjectVideoListState);
const pageScrollPosition = computed(() => store.state.projectVideoList.pageScrollPosition);
const isChooseProjectModalShowing = computed(() => store.state.videoUpload.isChooseProjectForUploadModalShowing);
const getVideoFromVideosUpload = computed(() => store.getters.getVideoFromVideosUpload);

const loadProjectListState = computed(() => store.state.projectList.loadProjectListState);
const projectListTotal = computed(() => store.state.projectList.projectListTotal);

const query = computed(() => route.query);
const teamName = computed(() => getQueryAsString(route.params, 'teamName'));
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const q = computed(() => getQueryAsString(route.query, 'q') ?? '');
const tagIds = computed(() => getQueryAsString(route.query, 'tagIds') ?? '');

const isAllProjects = computed(() => route.name === 'allProjectVideos');
const isEmptyVideo = computed(() => _.isEmpty(projectVideoList.value));
const isEmptySearch = computed(() => _.isEmpty(q.value));
const isEmptyTagIds = computed(() => _.isEmpty(tagIds.value));
const isFiltered = computed(() => !isEmptySearch.value || !isEmptyTagIds.value);
const isEmptyResultBySearch = computed(() => isEmptyVideo.value && isFiltered.value);
const isEmptyProjectVideo = computed(() => isEmptyVideo.value);

const isEmptyProject = computed(() => projectListTotal.value === 0);
const isLoadProjectListStateError = computed(() => !_.isEmpty(loadProjectListState.value?.error));

function isUploadPermissionDenied() {
  return currentProject.value && !currentProject.value.actions?.upload;
}

const emptyProjectVideoErrorMessage = computed(() => {
  if (query.value.tagIds) {
    return {
      statusCode: null,
      icon: 'fas fa-search',
      title: t('common:tag.resultEmpty.title'),
      description: t('common:tag.resultEmpty.description'),
      buttonTitle: t('common:search.resultEmpty.buttonTitle'),
      buttonUrl: `/${teamName.value}/projects/${projectKey.value}`,
      buttonStyle: 'primary',
    };
  }
  if (!isUploadPermissionDenied()) {
    return {
      statusCode: null,
      icon: 'fas fa-box-open',
      title: t('project.readyToUpload.title'),
      description: t('project.readyToUpload.subTitle'),
      buttonTitle: t('common:button.upload'),
      buttonUrl: `/${teamName.value}/projects/${projectKey.value}/upload`,
    };
  }
  return {
    statusCode: null,
    icon: 'fas fa-box-open',
    title: t('project.noPermission.title'),
    description: t('video.noPermission.helpText'),
  };
});

const emptyProjectErrorMessage = computed(() => {
  if (isLoadProjectListStateError.value && loadProjectListState.value.error) {
    return {
      statusCode: loadProjectListState.value.error.statusCode,
      title: `${t('response:errorUnknown.title')}`,
      description: `${t('response:errorUnknown.helpText')}
        <a href="mailto:support@byteark.com" target="_top">support@byteark.com</a>`,
    };
  }
  return {
    title: `${t('project.notFound.title')}`,
    icon: 'fas fa-inbox',
    buttonTitle: `${t('project.create.title')}`,
    buttonUrl: `/${teamName.value}/projects/create`,
    buttonPrefixIconClassName: 'fas fa-plus',
  };
});

async function load(filter: Filter) {
  const newFilter = { ...filter, ...(projectKey.value && { projectKey: projectKey.value }) };
  await store.dispatch('loadProjectVideoList', { filter: newFilter });
  if (projectKey.value) {
    await store.dispatch('loadCollectionList', { projectKey: projectKey.value });
  }
}

function onCloseUploadModal() {
  store.dispatch('setChooseProjectForUploadModalShowing', false);
}

function onChangeStateUploadModal() {
  store.dispatch(
    'setChooseProjectForUploadModalShowing',
    !isChooseProjectModalShowing.value,
  );
}

function isShowPagination() {
  return projectVideoListLastPage.value > 1;
}

function onSearchVideo(event: Event) {
  if (event.target && event.target instanceof HTMLInputElement) {
    router.push({
      query: { q: event.target.value },
    });
  }
}

function onToggleSlideOut() {
  isSlideOutToggle.value = !isSlideOutToggle.value;
  if (isSlideOutToggle.value) {
    document.body.classList.add('filter-sidebar-open');
  } else {
    document.body.classList.remove('filter-sidebar-open');
  }
}

function onOpenSlideOut() {
  isSlideOutToggle.value = true;
  document.body.classList.add('filter-sidebar-open');
}

function onCloseSlideOut() {
  isSlideOutToggle.value = false;
  document.body.classList.remove('filter-sidebar-open');
}

watch(query, (value) => {
  load(value);
  const pageNumber = typeof value.page === 'string' ? parseInt(value.page, 10) : 1;
  store.commit('setLastNavigatedVideoListPage', pageNumber);
}, { deep: true, immediate: true });

watch(route, onCloseUploadModal, { deep: true });

onMounted(() => {
  if (projectKey.value) {
    /** All Projects Video List page has no projectKey */
    store.dispatch('loadProjectByKey', projectKey.value);
  }

  store.dispatch('loadProjectList');
  store.dispatch('loadProjectVideoCaptionIssueList');
  store.dispatch('setChooseProjectForUploadModalShowing', false);
  store.dispatch('loadCountryList');

  if (projectKey.value || isAllProjects.value) {
    load(query.value);
  }

  if (route.query.tagIds !== undefined) {
    onToggleSlideOut();
  }

  if (pageScrollPosition.value) {
    window.scrollTo(0, pageScrollPosition.value);
  }
});

onBeforeUnmount(() => {
  if (isSlideOutToggle.value) {
    onToggleSlideOut();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (areProjectVideosMoved.value && currentProject.value)
      ? (_openBlock(), _createBlock(SuccessToast, {
          key: 0,
          duration: 10000,
          title: _unref(t)('project.moveVideos.success.title'),
          description: _unref(t)('project.moveVideos.success.description', { projectName: currentProject.value.name })
        }, null, 8, ["title", "description"]))
      : _createCommentVNode("", true),
    (isChooseProjectModalShowing.value)
      ? (_openBlock(), _createBlock(ChooseProjectForUploadModal, {
          key: 1,
          onCloseModal: onCloseUploadModal
        }))
      : _createCommentVNode("", true),
    (isSlideOutToggle.value)
      ? (_openBlock(), _createBlock(FilterSlideOut, {
          key: 2,
          onClose: onCloseSlideOut
        }))
      : _createCommentVNode("", true),
    (loadProjectVideoListState.value.status === 'success')
      ? (_openBlock(), _createBlock(Section, { key: 3 }, _createSlots({
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(Input, {
                    class: "form-control",
                    name: "keyword",
                    modelValue: q.value,
                    onChange: onSearchVideo,
                    isSearchInput: "",
                    placeholder: _unref(t)('videos.searchBy'),
                    dataTest: "input-search-videos"
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _createVNode(Button, {
                  type: "button",
                  onClick: onOpenSlideOut,
                  buttonStyle: "secondary",
                  dataTest: "filter-videos-button"
                }, {
                  "icon-prefix": _withCtx(() => _cache[0] || (_cache[0] = [
                    _createElementVNode("i", { class: "fa fa-filter filter-icon mr-1" }, null, -1)
                  ])),
                  content: _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('common:filter')), 1)
                  ]),
                  _: 1
                })
              ]),
              (projectKey.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!isUploadPermissionDenied())
                      ? (_openBlock(), _createBlock(Button, {
                          key: 0,
                          url: `/${teamName.value}/projects/${projectKey.value}/upload`,
                          buttonStyle: "primary",
                          buttonClasses: "btn-upload",
                          dataTest: "video-upload-button"
                        }, {
                          "icon-prefix": _withCtx(() => _cache[1] || (_cache[1] = [
                            _createElementVNode("i", { class: "fas fa-cloud-arrow-up" }, null, -1)
                          ])),
                          content: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('common:button.upload')), 1)
                          ]),
                          _: 1
                        }, 8, ["url"]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              (!projectKey.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (!isUploadPermissionDenied())
                      ? (_openBlock(), _createBlock(Button, {
                          key: 0,
                          onClick: _withModifiers(onChangeStateUploadModal, ["stop"]),
                          buttonStyle: "primary",
                          buttonClasses: "btn-upload"
                        }, {
                          "icon-prefix": _withCtx(() => _cache[2] || (_cache[2] = [
                            _createElementVNode("i", { class: "fas fa-cloud-arrow-up" }, null, -1)
                          ])),
                          content: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('common:button.upload')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            (!isEmptyProjectVideo.value)
              ? (_openBlock(), _createBlock(DataTable, {
                  key: 0,
                  "responsive-layout": "b",
                  class: "video-list-desktop",
                  "data-test": "videos-list-table"
                }, {
                  "table-header": _withCtx(() => [
                    _createVNode(Row, null, {
                      default: _withCtx(() => [
                        _createVNode(Column, { width: "140px" }, {
                          default: _withCtx(() => [
                            _createVNode(HeaderCell)
                          ]),
                          _: 1
                        }),
                        _createVNode(Column, {
                          minWidth: "200px",
                          isPrimaryColumn: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(HeaderCell, {
                              text: _unref(t)('videoList.table.videoInfo.title')
                            }, null, 8, ["text"])
                          ]),
                          _: 1
                        }),
                        _createVNode(Column, { minWidth: "130px" }, {
                          default: _withCtx(() => [
                            _createVNode(HeaderCell, { text: "Video Key" })
                          ]),
                          _: 1
                        }),
                        _createVNode(Column, { minWidth: "180px" }, {
                          default: _withCtx(() => [
                            _createVNode(HeaderCell, { text: "Geo-blocking" })
                          ]),
                          _: 1
                        }),
                        _createVNode(Column, { minWidth: "120px" }, {
                          default: _withCtx(() => [
                            _createVNode(HeaderCell, {
                              text: _unref(t)('common:form.updatedAt')
                            }, null, 8, ["text"])
                          ]),
                          _: 1
                        }),
                        _createVNode(Column, {
                          width: "140px",
                          isActionColumn: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(HeaderCell)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  "table-content": _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projectVideoList.value, (video, itemIndex) => {
                      return (_openBlock(), _createBlock(VideoItem, {
                        key: itemIndex,
                        video: getVideoFromVideosUpload.value(video),
                        columnType: columnType.value
                      }, null, 8, ["video", "columnType"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projectVideoList.value, (video, itemIndex) => {
                return (_openBlock(), _createBlock(VideoItem, {
                  key: itemIndex,
                  video: getVideoFromVideosUpload.value(video),
                  responsive: ""
                }, null, 8, ["video"]))
              }), 128))
            ]),
            (isEmptyProject.value)
              ? (_openBlock(), _createBlock(ErrorMessage, {
                  key: 1,
                  iconClassName: emptyProjectErrorMessage.value.icon,
                  title: emptyProjectErrorMessage.value.title,
                  description: emptyProjectErrorMessage.value.description,
                  buttonTitle: emptyProjectErrorMessage.value.buttonTitle,
                  buttonUrl: emptyProjectErrorMessage.value.buttonUrl,
                  buttonPrefixIconClassName: emptyProjectErrorMessage.value.buttonPrefixIconClassName
                }, null, 8, ["iconClassName", "title", "description", "buttonTitle", "buttonUrl", "buttonPrefixIconClassName"]))
              : _createCommentVNode("", true),
            (!isEmptyProject.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (isEmptyProjectVideo.value && !isFiltered.value)
                    ? (_openBlock(), _createBlock(MessageBox, {
                        key: 0,
                        iconClassName: emptyProjectVideoErrorMessage.value.icon,
                        title: emptyProjectVideoErrorMessage.value.title,
                        description: emptyProjectVideoErrorMessage.value.description,
                        buttonTitle: emptyProjectVideoErrorMessage.value.buttonTitle,
                        buttonUrl: emptyProjectVideoErrorMessage.value.buttonUrl,
                        buttonStyle: emptyProjectVideoErrorMessage.value.buttonStyle
                      }, null, 8, ["iconClassName", "title", "description", "buttonTitle", "buttonUrl", "buttonStyle"]))
                    : _createCommentVNode("", true),
                  (isEmptyResultBySearch.value)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (!isEmptySearch.value)
                          ? (_openBlock(), _createBlock(EmptySearch, {
                              key: 0,
                              keyword: q.value
                            }, null, 8, ["keyword"]))
                          : _createCommentVNode("", true),
                        (!isEmptyTagIds.value)
                          ? (_openBlock(), _createBlock(MessageBox, {
                              key: 1,
                              iconClassName: emptyProjectVideoErrorMessage.value.icon,
                              title: emptyProjectVideoErrorMessage.value.title,
                              description: emptyProjectVideoErrorMessage.value.description,
                              buttonTitle: emptyProjectVideoErrorMessage.value.buttonTitle,
                              buttonUrl: emptyProjectVideoErrorMessage.value.buttonUrl,
                              buttonStyle: emptyProjectVideoErrorMessage.value.buttonStyle
                            }, null, 8, ["iconClassName", "title", "description", "buttonTitle", "buttonUrl", "buttonStyle"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, [
          (!isEmptyProjectVideo.value && isShowPagination())
            ? {
                name: "section-footer",
                fn: _withCtx(() => [
                  (isShowPagination())
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        (!isEmptyProjectVideo.value)
                          ? (_openBlock(), _createBlock(Pagination, {
                              key: 0,
                              lastPage: projectVideoListLastPage.value
                            }, null, 8, ["lastPage"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                key: "0"
              }
            : undefined
        ]), 1024))
      : _createCommentVNode("", true),
    (loadProjectVideoListState.value.status === 'error')
      ? (_openBlock(), _createBlock(Section, { key: 4 }, {
          default: _withCtx(() => [
            _createVNode(ErrorMessage, {
              statusCode: loadProjectVideoListState.value.error?.statusCode,
              title: loadProjectVideoListState.value.error?.message,
              buttonTitle: _unref(t)('response:backToHomepage'),
              buttonUrl: `/${teamName.value}/projects`
            }, null, 8, ["statusCode", "title", "buttonTitle", "buttonUrl"])
          ]),
          _: 1
        }))
      : (loadProjectVideoListState.value.status === 'loading')
        ? (_openBlock(), _createBlock(PreLoaderSection, { key: 5 }))
        : _createCommentVNode("", true)
  ], 64))
}
}

})
<template>
  <div class="content-container">
    <div class="alert-container">
      <div class="icon-container">
        <i class="fas fa-triangle-exclamation" />
        <p class="alert-text">{{ t('project.deleteProject.step2.alert', { projectName }) }}</p>
      </div>
      <ul>
        <li v-if="!willMoveVideos">
          <strong>{{ videoCount }} {{ t(`glossary:video${videoCount > 1 ? '_plural' : ''}`) }}</strong>
        </li>
        <li><strong>{{ webhookList.length }} Webhook{{ webhookList.length > 1 ? 's' : '' }}</strong></li>
        <li>{{ t('project.deleteProject.step2.bullet3') }}</li>
        <li>{{ t('project.deleteProject.step2.bullet4') }}</li>
      </ul>
      <p v-if="willMoveVideos">
        {{
          t(`project.deleteProject.step2.moveVideos${videoCount > 1 ? '_plural' : ''}`, {
            sourceProjectName: projectName,
            destinationProjectName
          })
        }}
      </p>
    </div>
    <p class="description">
      <span v-html="t('project.deleteProject.step2.description1')" />
      {{ ' ' }}
      <router-link :to="{ name: 'deletedProjects' }">
        {{ t('project.deleteProject.step2.description2') }}
      </router-link>
      {{ ' ' }}
      <span v-html="t('project.deleteProject.step2.description3')" />
    </p>
    <div>
      <p class="input-label">
        {{ t('project.deleteProject.step2.description4') }}
        “<span class="code">delete {{ projectName }}</span>”
        {{ t('project.deleteProject.step2.description5') }}
      </p>
      <Input
        :modelValue
        @change="onChangeInput"
        :onPressedEnter="onConfirmDeleteProject"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import type { DeleteProjectData } from '../../../types';
import { useStore } from '../../../store/hooks';

interface ComponentProps {
  deleteProjectData: DeleteProjectData;
  modelValue: string;
  onConfirmDeleteProject: () => Promise<void>;
}

const props = defineProps<ComponentProps>();
const { deleteProjectData, modelValue, onConfirmDeleteProject } = toRefs(props);

const emit = defineEmits<{ 'update:modelValue': [value: string]}>();

const { t } = useI18n();
const store = useStore();

const projectName = computed(() => store.state.project.currentProject?.name ?? '');
const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);
const webhookList = computed(() => store.state.webhookList.webhookList);

const willMoveVideos = computed(() => deleteProjectData.value.actionWithVideos === 'moveVideos');
const destinationProjectName = computed(() => deleteProjectData.value.destinationProject?.name ?? '');

function onChangeInput(event: Event) {
  if (event.target instanceof HTMLInputElement) {
    emit('update:modelValue', event.target.value);
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';

.content-container {
  display: flex;
  flex-flow: column;
  gap: $spacing-24;
}

.alert-container {
  padding: $spacing-12;
  background-color: #fdf2f2;
  border: 1px solid $danger;
  border-radius: $border-radius-5;

  font-size: $font-level-7;
  font-weight: $font-weight-base;
  color: $grey-800;

  i {
    color: $danger;
    margin-top: 4px;
  }

}

.input-label {
  margin-bottom: $spacing-4;

  .code {
    font-family: $font-family-Monospace;
    color: #E83E8C;
  }
}

.description {
  color: $grey-800;
  font-size: $font-level-7;

  :deep(strong) {
    font-weight: 500;
  }
}

.icon-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing-8;
}
</style>

<template>
  <MoveVideosModal
    v-if="isShowMoveVideosModal"
    :onClose="() => isShowMoveVideosModal = false"
  />
  <DeleteVideosModal
    v-if="isShowDeleteVideosModal"
    :onClose="() => isShowDeleteVideosModal = false"
  />
  <DeleteProjectModal
    v-if="isShowDeleteProjectModal"
    :onClose="() => isShowDeleteProjectModal = false"
  />
  <section class="danger-zone-container">
    <div class="title-container">
      <i class="fas fa-exclamation-triangle" />
      <h2 class="title">DANGER ZONE</h2>
    </div>
    <div class="card-list">
      <DangerZoneCard
        :title="t('project.moveVideos.title')"
        :description="t('project.moveVideos.description')"
        :buttonText="t('project.moveVideos.button')"
        color="warning"
        buttonIcon="fas fa-arrow-turn-right"
        :onClickButton="() => isShowMoveVideosModal = true"
        :buttonDisabledReason="!videoCount ? t('project.moveVideos.disabledReason') : null"
      />
      <DangerZoneCard
        :title="t('project.deleteVideos.title')"
        :description="t('project.deleteVideos.description')"
        :buttonText="t('project.deleteVideos.button')"
        buttonIcon="fas fa-trash-alt"
        :onClickButton="() => isShowDeleteVideosModal = true"
        :buttonDisabledReason="!videoCount ? t('project.deleteVideos.disabledReason') : null"
      />
      <DangerZoneCard
        :title="t('project.deleteProject.title')"
        :description="t('project.deleteProject.description')"
        :buttonText="t('project.deleteProject.button')"
        buttonIcon="fas fa-trash-alt"
        :onClickButton="() => isShowDeleteProjectModal = true"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '../../../store/hooks';
import DangerZoneCard from '../../molecules/dangerZoneCard/DangerZoneCard.vue';
import MoveVideosModal from '../moveVideosModal/MoveVideosModal.vue';
import DeleteProjectModal from '../deleteProjectModal/DeleteProjectModal.vue';
import DeleteVideosModal from '../deleteVideosModal/DeleteVideosModal.vue';

const { t } = useI18n();
const store = useStore();

const isShowMoveVideosModal = ref<boolean>(false);
const isShowDeleteVideosModal = ref<boolean>(false);
const isShowDeleteProjectModal = ref<boolean>(false);

const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);
</script>

<style scoped lang="scss">
@import '~@/assets/scss/global-variables';

.danger-zone-container {
  margin-top: 64px;
}

.title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: $danger;
  font-size: $font-level-4;
  margin-bottom: $spacing-24;

  .title {
    font-size: inherit;
    font-weight: 500;
    color: inherit;
  }
}
</style>

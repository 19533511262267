import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "btn-close-container",
  "data-test": "modal-close-button"
}

import { toRefs } from 'vue';
import Button from '@/modules/shared/components/atoms/button/Button.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalCloseButton',
  props: {
    onCloseModal: { type: Function }
  },
  setup(__props: any) {

const props = __props;

const { onCloseModal } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Button, {
      type: "button",
      onClick: _withModifiers(_unref(onCloseModal), ["stop"]),
      buttonStyle: "text-secondary"
    }, {
      "icon-prefix": _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("i", { class: "fas fa-xmark" }, null, -1)
      ])),
      _: 1
    }, 8, ["onClick"])
  ]))
}
}

})
<template>
  <AddVideoCaptionModal
    v-if="isShowAddCaptionModal"
    :prefilledOption="addSubtitleMethod"
    :on-close="() => (isShowAddCaptionModal = false)" />
  <!-- open by row ellipsis menu -->
  <EditVideoCaptionSidebar
    v-if="isShowEditCaptionSidebar && selectedIndex >= 0"
    :captionIndex="selectedIndex"
    :on-close="() => {
      isShowEditCaptionSidebar = false;
      selectedIndex = -1;
    }"
  />
  <TranslateVideoCaptionModal
    v-if="isShowTranslateCaptionModal && selectedCaption"
    :caption="selectedCaption"
    :on-close="() => {isShowTranslateCaptionModal = false}"
  />
  <DeleteVideoCaptionModal
    v-if="isShowDeleteCaptionModal && selectedCaption"
    :caption="selectedCaption"
    :on-close="() => {isShowDeleteCaptionModal = false}"
  />
  <BadWordListModal
    v-if="isShowBadWordListModal && selectedCaption"
    :caption="selectedCaption"
    :on-close="() => {isShowBadWordListModal = false}"
  />
  <ChangeCaptionStatusModal
    v-if="isShowStatusModal && selectedIndex >= 0"
    :caption-index="selectedIndex"
    :onCloseModal="() => {isShowStatusModal = false}"
  />
  <PreLoaderSection v-if="loadingState?.status === 'loading'" />
  <!-- no caption -->
  <Section
    id="subtitles"
    class="video-caption-section"
    :title="t('video.subtitle.title')"
    v-if="loadingState?.status === 'success' && isCaptionEmpty && !isSearchEmpty"
  >
    <MessageBox
      :title="t('video.subtitle.empty.title')"
      :description="canUpdateVideo ? t('video.subtitle.empty.subtitle'): ''"
      :onClickButton="onOpenAddCaptionModal"
      buttonPrefixIconClassName="fas fa-plus"
      :buttonTitle="canUpdateVideo ? t('video.subtitle.add.button'): ''"
      button-style="primary"
    >
      <template v-slot:action v-if="canUpdateVideo && isAutoGenEnabled">
        <Button
          buttonStyle="text-primary"
          size="small"
          @click="onClickAutoGenCaption"
        >
          <template v-slot:icon-prefix>
            <i class="fas fa-plus" />
          </template>
          {{ t('video.subtitle.autoGen.button') }}
        </Button>
      </template>
    </MessageBox>
  </Section>
  <!-- has caption -->
  <Section
    id="subtitles"
    class="video-caption-section"
    :title="t('video.subtitle.title')"
    v-else-if="loadingState?.status === 'success'"
  >
    <DataTable
      :onSearchData="onSearchCaption"
      :searchInputPlaceholder="t('video.subtitle.search.placeholder')"
      :searchInputValue
      responsiveLayout="b"
    >
      <template v-slot:table-action v-if="canUpdateVideo">
        <Button type="button" buttonStyle="secondary" @click="isShowAddCaptionModal = true">
          <template v-slot:icon-prefix>
            <i class="fas fa-plus"></i>
          </template>
          <template v-slot:content>
            {{ t('video.subtitle.add.button') }}
          </template>
        </Button>
      </template>
      <template v-slot:table-header v-if="!isCaptionEmpty && !isSearchEmpty">
        <Row>
          <Column minWidth="140px">
            <HeaderCell :text="t('video.subtitle.displayName.title')" />
          </Column>
          <Column width="60px">
            <HeaderCell :text="t('video.subtitle.language.title')" />
          </Column>
          <Column width="150px">
            <HeaderCell :text="t('video.subtitle.status.title')" />
          </Column>
          <Column width="80px">
            <HeaderCell :text="t('video.subtitle.table.header.updatedAt')" />
          </Column>
          <Column width="85px">
            <div class="bad-word-header">
              {{ t('video.subtitle.badWords.title')}}
              <Tooltip>
                <i class="fas fa-info-circle"></i>
                <template v-slot:tooltip-text>
                  {{ t('video.subtitle.table.header.badWords.helpText') }}
                </template>
              </Tooltip>
            </div>
          </Column>
          <Column width="100px" isActionColumn />
        </Row>
      </template>
      <template v-slot:table-content v-if="!isSearchEmpty">
        <VideoCaptionRow
          v-for="(caption,index) in captionList"
          :key="`${caption.customName}-${index}`"
          :index="index"
          @on-open-edit-sidebar="onOpenEditSidebar(index)"
          @on-open-translate-caption-modal="onOpenTranslateCaptionModal(caption)"
          @on-open-delete-caption-modal="onOpenDeleteCaptionModal(caption)"
          @on-open-badword-list-modal="(event) => onOpenBadwordListModal(event, caption)"
          @on-open-status-modal="(event) => onOpenStatusModal(event,index)"
          @download-file="downloadFile(caption)"
          :readonly="!canUpdateVideo"
        />
      </template>
      <template v-slot:table-footer v-if="lastPage > 1 && !isCaptionEmpty && !isSearchEmpty">
        <Pagination :lastPage="lastPage" @click="isShowEditCaptionSidebar = false" />
      </template>
    </DataTable>
    <!--empty search-->
    <div v-if="isSearchEmpty">
      <EmptySearch :keyword="searchQuery" />
    </div>
  </Section>
  <!-- error -->
  <Section v-if="loadingState?.status === 'error'">
    <ErrorMessage
      :statusCode="loadingState?.error?.statusCode"
      :title="loadingState?.error?.message"
    />
  </Section>
</template>

<script lang="ts" setup>
import { Caption } from '@/modules/videoCaption/types';
import { getQueryAsString } from '@/modules/shared/utils/query';
import isEmpty from 'lodash/isEmpty';
import {
  computed, ref, onBeforeUnmount, onMounted, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import BadWordListModal from '@/modules/videoDetail/components/organisms/badWordListModal/BadWordListModal.vue';
import Tooltip from '@/modules/shared/components/atoms/tooltip/Tooltip.vue';
import Pagination from '@/modules/shared/components/atoms/pagination/Pagination.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import TranslateVideoCaptionModal from '../translateVideoCaptionModal/TranslateVideoCaptionModal.vue';
import EditVideoCaptionSidebar from '../editVideoCaptionSidebar/EditVideoCaptionSidebar.vue';
import DeleteVideoCaptionModal from '../deleteVideoCaptionModal/DeleteVideoCaptionModal.vue';
import VideoCaptionRow from '../../molecules/videoCaptionRow/VideoCaptionRow.vue';
import ChangeCaptionStatusModal from '../changeVideoCaptionStatusModal/ChangeVideoCaptionStatusModal.vue';
import AddVideoCaptionModal from '../addVideoCaptionModal/AddVideoCaptionModal.vue';
import { downloadCaptionFile } from '../../../services';
import { useStore } from '../../../store/hooks';

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const filter = computed(() => route.query);
const captionList = computed(() => store.state.captionList?.captionList);
const lastPage = computed(() => store.state.captionList?.lastPage || 1);
const loadingState = computed(() => store.state.captionList?.loadingState);
const isAutoGenEnabled = computed(() => store.state.teamFeatures?.teamFeatures?.['auto-generate-caption']);
const currentVideo = computed(() => store.state.video?.currentVideo);
const canUpdateVideo = computed(() => store.state.project.currentProject?.actions?.updateVideo);

const isShowAddCaptionModal = ref<boolean>(false);
const isShowEditCaptionSidebar = ref<boolean>(false);
const isShowTranslateCaptionModal = ref<boolean>(false);
const isShowDeleteCaptionModal = ref<boolean>(false);
const isShowBadWordListModal = ref<boolean>(false);
const isShowStatusModal = ref<boolean>(false);
const selectedCaption = ref<Caption | null>(null);
const selectedIndex = ref<number>(-1);
const addSubtitleMethod = ref<string>('');

// null state before loading or empty list after loading
const isCaptionEmpty = computed<boolean>(() => isEmpty(captionList.value));

watch(lastPage, (value) => {
  if (value !== null && Number(filter.value?.page) > Number(value)) {
    // invalid page number
    router.push({ path: route.path, query: { ...route.query, page: 1 } });
  }
});

// search caption
const onSearchCaption = (event: Event): void => {
  if (event.target && event.target instanceof HTMLInputElement) {
    const query = event.target.value.toLowerCase();
    router.push({ query: { q: query } });
  }
};

const searchQuery = computed(() => getQueryAsString(filter.value, 'q'));
const isSearchEmpty = computed(() => Boolean(searchQuery.value && isCaptionEmpty.value));

const searchInputValue = ref<string>('');

const onOpenEditSidebar = (index: number): void => {
  isShowEditCaptionSidebar.value = true;
  selectedIndex.value = index;
};

const onClickAutoGenCaption = (): void => {
  addSubtitleMethod.value = 'auto-gen';
  isShowAddCaptionModal.value = true;
};

const onOpenAddCaptionModal = (): void => {
  addSubtitleMethod.value = 'upload';
  isShowAddCaptionModal.value = true;
};

const onOpenTranslateCaptionModal = (caption: Caption): void => {
  isShowTranslateCaptionModal.value = true;
  selectedCaption.value = caption;
};

const onOpenDeleteCaptionModal = (caption: Caption): void => {
  isShowDeleteCaptionModal.value = true;
  selectedCaption.value = caption;
};

const onOpenBadwordListModal = (event: MouseEvent, caption: Caption): void => {
  event.stopPropagation();
  isShowBadWordListModal.value = true;
  selectedCaption.value = caption;
};

const onOpenStatusModal = (event: MouseEvent, index: number): void => {
  event.stopPropagation();
  isShowStatusModal.value = true;
  selectedIndex.value = index;
};

const downloadFile = async ({ key, originalFilename, customName }: Caption): Promise<void> => {
  const fileName = originalFilename || `${customName}.vtt`;
  try {
    const captionKey = key;
    const response = await downloadCaptionFile(captionKey);
    const captionFile = new File([response.data], `${fileName}`, {
      type: 'text/vtt',
    });
    const fileURL = URL.createObjectURL(captionFile);

    // create dummy  <a></a> for file download
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = `${fileName}`;

    a.click();
    URL.revokeObjectURL(fileURL);
    // eslint-disable-next-line no-empty
  } catch (err) {
    // TODO: Handle error, create ui to display error message when download failed
    // console.log(err);
  }
};

const loadCaptionList = async () => {
  await store.dispatch('loadCaptionList', {
    ...(filter.value || {}),
    videoKey: currentVideo.value?.key,
  });
};

const unwatch = watch(
  () => filter.value,
  () => {
    loadCaptionList();
  },
);

onMounted(() => {
  if (!captionList.value) {
    loadCaptionList();
  }

  if (searchQuery.value) {
    searchInputValue.value = searchQuery.value;
  }

  store.dispatch('loadLanguageList');
});

onBeforeUnmount(() => {
  store.dispatch('destroyCaptionList');
});

onBeforeRouteLeave(() => {
  // watch can trigger on route leave and load caption list again
  // remove async watch to fix
  unwatch();
});

</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.video-caption-section {
  overflow: visible;
}

.bad-word-header {
  font-size: $font-level-8;
  font-weight: $font-weight-bold;
  /* padding: $spacing-8 0; */
  text-transform: capitalize;
  background: $grey-100;
  text-align: right;
  width: 100%;

  :deep(.fas) {
    margin-left: $spacing-4;
    color: $grey-400;
  }
}

:deep(.dropdown-item) {
  color: $grey-800;
  margin-right: 0px;
}

.icon-box {
  width: 16px;
  height: 16px;
  font-size: 12px;
  display: inline-block;
  text-align: right;
}
:deep(.btn-add-subtitle) {
  margin: auto;
  margin-top: $spacing-base * 1.5;
}
// :deep(.btn-clear-search) {
//   margin: auto;
//   margin-top: $spacing-base * 1.5;
// }
.date-text {
  white-space: nowrap;
}

.badword-highlight {
  background-color: #fef9f1;
}

.processing {
  opacity: 0.3;
  cursor: not-allowed;
}

.bad-word-count {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
  align-items: center;
}

.warning-icon {
  color: $warning;
  font-size: $font-level-8;
}

.empty-message-container {
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  /* :deep(.button) {
    margin: auto;
    margin-top: $spacing-24;
  } */

  .empty-title {
    color: #343a40;
    font-weight: $font-weight-bold;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .empty-subtitle {
    color: #9ea4a9;
    font-size: 14px;
  }
}

.text-align-end {
  text-align: end;
}

.empty-search {
  flex-direction: column;
  justify-content: center;
}

:deep(.sub-text) {
  text-transform: capitalize;
}

:deep(.btn-dropdown) {
  /* margin-left: $spacing-base; */
  padding: 0;
}

:deep(.data-table-container) {
  overflow: visible;
}
</style>

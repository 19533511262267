import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "radio-btn-group-container" }
const _hoisted_2 = { class: "radio-btn-group-content" }
const _hoisted_3 = ["onClick", "id", "data-test"]
const _hoisted_4 = {
  key: 0,
  class: "help-text"
}

import RadioButton from '@/modules/shared/components/atoms/radioButton/RadioButton.vue';
import cloneDeep from 'lodash/cloneDeep';
import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { RadioButtonGroupProps } from './RadioButtonGroup.type';


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioButtonGroup',
  props: {
    disabled: { type: Boolean },
    isTranslationText: { type: Boolean },
    helpText: {},
    options: {},
    onChange: { type: Function },
    subFieldMt8: { type: Boolean },
    modelValue: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const {
  modelValue,
  disabled,
  isTranslationText,
  helpText,
  options,
  onChange,
  subFieldMt8,
} = toRefs(props);

/** Value may be anything. */
const emit = __emit;

const { t } = useI18n();
const value = ref(cloneDeep(modelValue.value));

function onSelectOption(selected: unknown): void {
  value.value = selected;

  if (typeof onChange?.value === 'function') {
    onChange.value(selected);
  }

  emit('update:modelValue', selected);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options), (option, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["option-wrapper", {'active-option': value.value === option.value, 'disabled': _unref(disabled) || option.disabled}]),
          onClick: _withModifiers(($event: any) => (!_unref(disabled) && !option.disabled && onSelectOption(option.value)), ["stop"]),
          key: i,
          id: `radio-button-${i}`,
          "data-test": `radio-button-${i}`
        }, [
          _createVNode(RadioButton, {
            label: _unref(isTranslationText) ? _unref(t)(option.label) : option.label,
            subLabel: option.subLabel,
            description: _unref(isTranslationText) ? _unref(t)(option.description || '') : option.description,
            warning: option.warning,
            modelValue: _unref(modelValue) === option.value,
            disabled: _unref(disabled) || option.disabled,
            subFieldMt8: _unref(subFieldMt8),
            "onUpdate:modelValue": ($event: any) => (!_unref(disabled) && !option.disabled && onSelectOption(option.value))
          }, _createSlots({ _: 2 }, [
            (option.subFieldName)
              ? {
                  name: "subField",
                  fn: _withCtx(() => [
                    _renderSlot(_ctx.$slots, option.subFieldName)
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["label", "subLabel", "description", "warning", "modelValue", "disabled", "subFieldMt8", "onUpdate:modelValue"])
        ], 10, _hoisted_3))
      }), 128))
    ]),
    (_unref(helpText))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(helpText)), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label", "data-test", "disabled", "type"]
const _hoisted_2 = {
  key: 0,
  class: "fa fa-spinner fa-spin"
}
const _hoisted_3 = {
  key: 0,
  class: "fa fa-spinner fa-spin"
}
const _hoisted_4 = ["href", "disabled"]
const _hoisted_5 = {
  key: 0,
  class: "fa fa-spinner fa-spin"
}

import { toRefs } from 'vue';
import { ButtonProps } from '@/types/Button.type';


export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
    ariaLabel: {},
    buttonClasses: {},
    buttonStyle: { default: 'primary' },
    disabled: { type: Boolean },
    isExternalUrl: { type: Boolean },
    isFullWidth: { type: Boolean },
    isSaving: { type: Boolean },
    isSquare: { type: Boolean },
    size: { default: 'default' },
    type: { default: 'button' },
    url: {},
    dataTest: { default: '' },
    alignIcon: {},
    isRelative: { type: Boolean }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  buttonClasses,
  buttonStyle,
  disabled,
  isExternalUrl,
  isRelative,
  isFullWidth,
  isSaving,
  isSquare,
  size,
  type,
  url,
  dataTest,
  alignIcon,
} = toRefs(props);

const emit = __emit;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(url))
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          "aria-label": _ctx.ariaLabel,
          class: _normalizeClass(["button", [
      _unref(buttonStyle) || 'primary',
      _unref(disabled) ? 'disabled' : '',
      _unref(isFullWidth) ? 'full-width' : '',
      !_ctx.$slots['content'] && !_ctx.$slots['default'] ? 'square' : '',
      _unref(isSquare) ? 'square-padding square' : '',
      _unref(size),
      _unref(buttonClasses),]]),
          "data-test": _unref(dataTest),
          disabled: _unref(disabled) || _unref(isSaving),
          type: _unref(type),
          onClick: _cache[0] || (_cache[0] = (event) => emit('click', event))
        }, [
          (_unref(isSaving))
            ? (_openBlock(), _createElementBlock("i", _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.$slots['icon-prefix'] && !_unref(isSaving))
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["icon-prefix", {
        'only-child': !_ctx.$slots['content'] && !_ctx.$slots['default'] && !_ctx.$slots['icon-suffix'],
        'align-icon-center': _unref(alignIcon) === 'center',
      }])
              }, [
                _renderSlot(_ctx.$slots, "icon-prefix")
              ], 2))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "content"),
          (!_ctx.$slots['content'])
            ? _renderSlot(_ctx.$slots, "default", { key: 2 })
            : _createCommentVNode("", true),
          (_ctx.$slots['icon-suffix'])
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass(["icon-suffix", { 'only-child': !_ctx.$slots['content'] && !_ctx.$slots['default'] && !_ctx.$slots['icon-prefix'] }])
              }, [
                _renderSlot(_ctx.$slots, "icon-suffix")
              ], 2))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (_unref(url) && !_unref(isExternalUrl))
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          class: _normalizeClass(["button", [
      _unref(buttonStyle),
      _unref(disabled) ? 'disabled' : '',
      _unref(isFullWidth) ? 'fullWidth' : '',
      !_ctx.$slots['content'] && !_ctx.$slots['default'] ? 'square' : '',
      _unref(isSquare) ? 'square-padding square' : '',
      _unref(size),
      _unref(buttonClasses),
    ]]),
          disabled: _unref(disabled),
          to: _unref(url),
          "data-test": _unref(dataTest),
          onClick: _cache[1] || (_cache[1] = _withModifiers((event) => emit('click', event), ["stop"]))
        }, {
          default: _withCtx(() => [
            (_unref(isSaving))
              ? (_openBlock(), _createElementBlock("i", _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.$slots['icon-prefix'] && !_unref(isSaving))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["icon-prefix", { 'only-child': !_ctx.$slots['content'] && !_ctx.$slots['default'] && !_ctx.$slots['icon-suffix'] }])
                }, [
                  _renderSlot(_ctx.$slots, "icon-prefix")
                ], 2))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "content"),
            (!_ctx.$slots['content'])
              ? _renderSlot(_ctx.$slots, "default", { key: 2 })
              : _createCommentVNode("", true),
            (_ctx.$slots['icon-suffix'])
              ? (_openBlock(), _createElementBlock("div", {
                  key: 3,
                  class: _normalizeClass(["icon-suffix", { 'only-child': !_ctx.$slots['content'] && !_ctx.$slots['default'] && !_ctx.$slots['icon-prefix'] }])
                }, [
                  _renderSlot(_ctx.$slots, "icon-suffix")
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["class", "disabled", "to", "data-test"]))
      : _createCommentVNode("", true),
    (_unref(url) && _unref(isExternalUrl))
      ? (_openBlock(), _createElementBlock("a", {
          key: 2,
          class: _normalizeClass(["button", [
      _unref(buttonStyle),
      _unref(disabled) ? 'disabled' : '',
      _unref(isFullWidth) ? 'fullWidth' : '',
      !_ctx.$slots['content'] && !_ctx.$slots['default'] ? 'square' : '',
      _unref(isSquare) ? 'square-padding square' : '',
      _unref(size),
      _unref(buttonClasses),
      _unref(isRelative) ? 'relative' : '',
    ]]),
          href: _unref(url),
          disabled: _unref(disabled),
          target: "_blank",
          onClick: _cache[2] || (_cache[2] = _withModifiers((event) => emit('click', event), ["stop"]))
        }, [
          (_unref(isSaving))
            ? (_openBlock(), _createElementBlock("i", _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.$slots['icon-prefix'] && !_unref(isSaving))
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["icon-prefix", { 'only-child': !_ctx.$slots['content'] && !_ctx.$slots['default'] && !_ctx.$slots['icon-suffix'] }])
              }, [
                _renderSlot(_ctx.$slots, "icon-prefix")
              ], 2))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "content"),
          (!_ctx.$slots['content'])
            ? _renderSlot(_ctx.$slots, "default", { key: 2 })
            : _createCommentVNode("", true),
          (_ctx.$slots['icon-suffix'])
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass(["icon-suffix", { 'only-child': !_ctx.$slots['content'] && !_ctx.$slots['default'] && !_ctx.$slots['icon-prefix'] }])
              }, [
                _renderSlot(_ctx.$slots, "icon-suffix")
              ], 2))
            : _createCommentVNode("", true)
        ], 10, _hoisted_4))
      : _createCommentVNode("", true)
  ], 64))
}
}

})
<template>
  <ConfirmModal
    size="small"
    :title="$t('project.deleteVideos.recover.tittle')"
    :buttonTitle="$t('common:recover')"
    buttonStyle="primary"
    :onClose="onCloseModal"
    :onConfirm="recoverVideo"
    :disableConfirmButton="isRecovering"
  >
    <template v-slot:body>
      <div v-if="deletedProject" class="description">
        <div class="alert-container">
          <Alert
            v-if="!isLoading && !isEmptyRecentlyDeleted"
            :alertText="$t('project.deleteVideos.recover.alert')"
            priority="warning"
          />
        </div>
        <div>
          <p>
            {{$t('project.deleteVideos.recover.description_1')}}
            <span class="highlight-name">“{{videoName}}”</span>
            {{$t('project.deleteVideos.recover.description_2')}}
          </p>
          <div class="recover-video-method-selector-container">
            <RecoverVideoMethodSelector
              :selectedMethod="selectedMethod"
              :deletedProject="deletedProject"
              :onChangeSelectedMethod="onChangeSelectedMethod"
              :onSelectRestoreProject="onSelectRestoreProject"
            />
          </div>
        </div>
      </div>
      <div v-else class="description" v-html="$t('recentlyDeleted.subtitle', { videoName, projectName } )" />
    </template>
  </ConfirmModal>
</template>

<script>
import _ from 'lodash';
import ConfirmModal from '@/modules/shared/components/organisms/confirmModal/ConfirmModal.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import { loadProjectByKey } from '@/modules/shared';
import RecoverVideoMethodSelector from '../../molecules/RecoverVideoMethodSelector.vue';
import { restoreDeletedProject, restoreDeletedVideo, restoreDeletedVideoToProject } from '../../../services';

export default {
  props: {
    onCloseModal: {
      type: Function,
      default: () => {},
    },
    video: {
      type: Object,
      required: true,
    },
  },
  emits: ['success', 'restoreDeletedProjectSuccess', 'restoreToAnotherProjectSuccess'],
  components: {
    ConfirmModal,
    Alert,
    RecoverVideoMethodSelector,
  },
  data() {
    return {
      error: null,
      isRecovering: false,
      recoverOptions: [],
      selectedMethod: 'restoreToCurrent',
      selectedProject: null,
      restoreDeletedProjectSuccess: false,
    };
  },
  computed: {
    projectKey() {
      return this.$route.params.projectKey;
    },
    projectName() {
      return _.get(this.video, 'project[0].name', '');
    },
    videoKey() {
      return _.get(this.video, 'key', null);
    },
    videoName() {
      return _.get(this.video, 'title', '');
    },
    deletedProjectList() {
      return this.$store.state.projectList.deletedProjectList;
    },
    deletedProject() {
      const projectId = _.get(this.video, 'projectId', null);
      if (!this.deletedProjectList || !projectId) {
        return null;
      }
      return this.deletedProjectList.find((project) => project.id === projectId);
    },
    deletedProjectName() {
      return _.get(this.deletedProject, 'name', '');
    },
    recoveredProjectIds() {
      return this.$store.state.projectDeletedVideoList.recoveredProjectIds;
    },
  },
  methods: {
    async recoverVideo() {
      this.isRecovering = true;
      try {
        if (!this.deletedProject) {
          await restoreDeletedVideo(this.videoKey);
        } else {
          if (this.selectedMethod === 'restoreToCurrent') {
            const projectKey = _.get(this.deletedProject, 'key', null);
            const projectId = _.get(this.deletedProject, 'id', null);
            await restoreDeletedProject(projectKey);
            this.$store.dispatch('updateRecoveredProjectIds', [...this.recoveredProjectIds, projectId]);
            const recoveredProjectResponse = await loadProjectByKey(projectKey);
            const { name, videoCount } = recoveredProjectResponse.data;
            this.$emit('restoreDeletedProjectSuccess', { projectName: name, videoCount });
          }
          if (this.selectedMethod === 'restoreToAnother') {
            const videoName = _.get(this.video, 'title', '');
            const projectName = _.get(this.selectedProject, 'name', '');
            const projectKey = _.get(this.selectedProject, 'key', null);
            await restoreDeletedVideoToProject(this.videoKey, { projectKey });
            this.$emit('restoreToAnotherProjectSuccess', { projectName, videoName });
          }
        }
        this.isRecovering = false;
        this.onCloseModal();
        this.$emit('success');
      } catch (error) {
        this.error = error;
        this.isRecovering = false;
      }
    },
    async onChangeSelectedMethod(choice) {
      if (choice === this.selectedMethod) {
        return;
      }
      this.selectedMethod = choice;
    },
    async onSelectRestoreProject(project) {
      this.selectedProject = project;
    },
  },
  created() {
    this.$store.dispatch('loadDeletedProjectList');
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

.title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.page .page-header .modal-header .title {
  color: $body-color;
}

.recover-video-method-selector-container {
  margin-top: $spacing-8;
}

.highlight-name {
  font-weight: $font-weight-bold;
}

.alert-container {
  margin-bottom: $spacing-24;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-test"]

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Column',
  props: {
  // 100px, 100%
  width: {
    type: String,
  },
  minWidth: {
    type: String,
  },
  isActionColumn: {
    type: Boolean,
    default: false,
  },
  isPrimaryColumn: {
    type: Boolean,
    default: false,
  },
  horizontalAlign: {
    type: String,
    default: 'left',
  },
  verticalAlign: {
    type: String,
    default: 'top',
  },
  dataTest: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const horizontalAlignClassName = computed(() => {
  if (props.isActionColumn) {
    return 'horizontal-align-right';
  }
  return `horizontal-align-${props.horizontalAlign}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["column", [
      {
        'full-width' : !__props.width,
        'action-column' : __props.isActionColumn,
        'primary-column': __props.isPrimaryColumn
      }, `${horizontalAlignClassName.value}`,
      `vertical-align-${__props.verticalAlign}`,
    ]]),
    "data-test": __props.dataTest,
    style: _normalizeStyle({ width: __props.width, minWidth: __props.minWidth || __props.width })
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 14, _hoisted_1))
}
}

})
import type { ActionContext } from 'vuex';
import type { Filter } from '@/types/Axios.type';
import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
import type { LoadingState } from '@/types/State.type';
import { getCaptionDraftList } from '@/modules/videoCaption/services';
import type { CaptionDraft, CaptionListState } from '@/modules/videoCaption/types';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import type { RootState } from '@/store/type';

type CaptionListContext = ActionContext<CaptionListState, RootState>;

// state
const initialState = (): CaptionListState => ({
  captionList: null,
  lastPage: null,
  total: null,
  loadingState: setStateLoadingStatusByType(),
});

const state = initialState();

// mutation
const mutations = {
  setCaptionList(state: CaptionListState, captionList: CaptionDraft[]) {
    state.captionList = captionList;
  },
  updateCaptionByIndex(
    state: CaptionListState,
    { index, caption }: { index: number; caption: CaptionDraft },
  ) {
    if (state.captionList) {
      state.captionList[index] = caption;
    }
  },
  setLoadingState(state: CaptionListState, loadingState: LoadingState) {
    state.loadingState = setStateLoadingStatusByType(loadingState);
  },
  setCaptionListLastPage(state: CaptionListState, lastPage: number) {
    state.lastPage = lastPage;
  },
  setTotalCaptionList(state: CaptionListState, total: number) {
    state.total = total;
  },
  destroyCaptionList(state: CaptionListState) {
    Object.assign(state, initialState());
  },
};

// action
const actions = {
  async loadCaptionList({ commit }: CaptionListContext, payload: Filter) {
    commit('setLoadingState', { type: 'loading' });
    const { videoKey, ...filter } = payload;

    if (typeof videoKey !== 'string') {
      commit('setLoadingState', {
        type: 'error',
        error: 'A project key is missing.',
      });
      return;
    }

    try {
      // caption drafts
      const response = await getCaptionDraftList(videoKey, filter);
      const { data, lastPage, total } = response.data;
      const captionDraftList = data;

      commit('setCaptionList', captionDraftList);
      commit('setCaptionListLastPage', lastPage);
      commit('setTotalCaptionList', total);
      commit('setLoadingState', { type: 'success' });
    } catch (error) {
      commit('setLoadingState', {
        type: 'error',
        error: formatErrorObject(error, 'Caption Draft List'),
      });
    }
  },

  async reloadCaptionList({ commit, dispatch }: CaptionListContext, payload: Filter) {
    const { videoKey, ...filter } = payload;

    if (typeof videoKey !== 'string') {
      commit('setLoadingState', {
        type: 'error',
        error: 'A project key is missing.',
      });
      return;
    }

    await dispatch('loadCaptionList', { videoKey, filter });
  },

  updateCaptionByIndex(
    { commit, state }: CaptionListContext,
    { index, payload }: { index: number; payload: Partial<CaptionDraft> },
  ) {
    if (state.captionList) {
      const caption = { ...state.captionList[index], ...payload };
      commit('updateCaptionByIndex', { index, caption });
    }
  },

  destroyCaptionList({ commit }: CaptionListContext) {
    commit('destroyCaptionList');
  },
};

export default {
  state,
  mutations,
  actions,
};

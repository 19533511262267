import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import languageList from '@/store/modules/languageList';
import project from '@/modules/shared/store/project';
import captionList from '../store/captionList';
import subtitle from '../store/subtitle';

export function createVideoCaptionRoutes(): RouteRecordRaw[] {
  return [
    {
      path: 'subtitles',
      component: () => import(/* webpackChunkName: "VideoSubtitles" */ '../pages/VideoSubtitles.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        if (!store.hasModule('captionList')) {
          store.registerModule('captionList', captionList);
        }
        if (!store.hasModule('subtitle')) {
          store.registerModule('subtitle', subtitle);
        }
        if (!store.hasModule('languageList')) {
          store.registerModule('languageList', languageList);
        }
        next();
      },
    },
  ];
}

import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "video-output-selector" }
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_3 = {
  key: 1,
  class: "loading-container"
}
const _hoisted_4 = {
  key: 2,
  class: "options-container"
}
const _hoisted_5 = { class: "preset-name" }

import _ from 'lodash';
import { onMounted, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import RadioButtonChoice from '@/modules/shared/components/atoms/radioButtonChoice/RadioButtonChoice.vue';
import { type Preset, loadPresets, PresetSummaryInfo } from '@/modules/preset';

interface VideoOutputSelectorProps {
  presetData: Preset;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoOutputSelector',
  props: {
    presetData: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { presetData } = toRefs(props);

const emit = __emit;

const { t } = useI18n();

const presets = ref<Array<Preset>>([]);
const selectedPreset = ref<Preset | null>(null);
const loadPresetState = ref<{ status: string; errorCode: number | null }>({
  status: 'idle',
  errorCode: null,
});

async function load(filter: Record<string, string>) {
  loadPresetState.value.status = 'loading';
  try {
    const response = await loadPresets(filter);
    presets.value = response.data.data;
    loadPresetState.value.status = 'success';
  } catch (error) {
    loadPresetState.value.errorCode = _.get(error, 'response.status', 500);
    loadPresetState.value.status = 'error';
  }
}

function isCurrentPreset(preset: Preset) {
  const currentPresetId = _.get(selectedPreset.value, 'id', null);
  return preset.id === currentPresetId;
}

function onSelectedPreset(presetId: string) {
  selectedPreset.value = presets.value.find((p) => p.id === presetId) ?? null;
  emit('update:modelValue', selectedPreset.value);
}

onMounted(() => {
  load({ limit: '100' });
  selectedPreset.value = _.cloneDeep(presetData.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (loadPresetState.value.status === 'loading')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fas fa-circle-notch fa-spin fa-4x" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (loadPresetState.value.status === 'error')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(t)('response:loadingFailed.title')), 1))
      : _createCommentVNode("", true),
    (loadPresetState.value.status === 'success')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(presets.value, (preset, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "form-radio",
              key: preset.id
            }, [
              _createVNode(RadioButtonChoice, {
                modelValue: isCurrentPreset(preset),
                data: preset.id,
                "onUpdate:data": onSelectedPreset,
                dataTest: `preset-radio-button-${index}`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(preset.name), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(preset.profiles, (profile) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "preset-profile-wrapper",
                      key: profile.id
                    }, [
                      _createVNode(_unref(PresetSummaryInfo), { profile: profile }, null, 8, ["profile"])
                    ]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["modelValue", "data", "dataTest"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})
/* eslint-disable object-curly-newline */
// import type { ActionContext } from 'vuex';
// import type { Filter } from '@/types/Axios.type';
// import { setStateLoadingStatusByType } from '@/modules/shared/utils/stateManagement';
// import type { LoadingState } from '@/types/State.type';
// import { getCaptionDraftList } from '@/modules/videoCaption/services';
// import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
// import type { RootState } from '@/store/type';
import type { CaptionDraft, EditingLine, SubtitleState } from '../types';

// type SubtitleContext = ActionContext<SubtitleState, RootState>;

const getDefaultLocalEditingLines = (lineNo = 1) => [{
  lineNo,
  text: '',
  startTime: 0,
  endTime: 3,
  startTimeError: false,
  endTimeError: false,
}];

// state
const initialState = (): SubtitleState => ({
  currentSubtitle: null,
  currentTime: 0,
  newCurrentTime: -1,
  currentlyEditingSubtitleLineIndex: -1,
  localEditingLines: getDefaultLocalEditingLines(),
  zoomSteps: [0.25, 0.75, 2, 4],
  currentZoom: 2,
  isPlayerPaused: true,
});

const state = initialState();

const getters = {
  currentlyPlayingSubtitleLineIndex: (state: SubtitleState) => (
    state.localEditingLines.findIndex((line) => (state.currentTime >= line.startTime && state.currentTime <= line.endTime)) ?? -1
  ),
};

// mutation
const mutations = {
  setCurrentSubtitle(state: SubtitleState, value: CaptionDraft) {
    state.currentSubtitle = value;
    if (value?.captionContent && value.captionContent.length > 0) {
      state.localEditingLines = value.captionContent.map((object, index) => ({
        ...object,
        lineNo: index + 1,
        startTimeError: false,
        endTimeError: false,
      }));
    } else {
      state.localEditingLines = getDefaultLocalEditingLines();
    }
  },
  setCurrentTime(state: SubtitleState, value: SubtitleState['currentTime']) {
    state.currentTime = value;
  },
  setNewCurrentTime(state: SubtitleState, value: SubtitleState['newCurrentTime']) {
    state.newCurrentTime = value;
  },
  setCurrentZoom(state: SubtitleState, value: SubtitleState['currentZoom']) {
    state.currentZoom = value;
  },
  setCurrentlyEditingSubtitleLineIndex(state: SubtitleState, value: SubtitleState['currentlyEditingSubtitleLineIndex']) {
    state.currentlyEditingSubtitleLineIndex = value;
  },
  setIsPlayerPaused(state: SubtitleState, value: SubtitleState['isPlayerPaused']) {
    state.isPlayerPaused = value;
  },
  setLocalEditingLines(state: SubtitleState, value: SubtitleState['localEditingLines']) {
    state.localEditingLines = value;
  },
  updateLocalEditingLine(state: SubtitleState, value: { index: number, line: EditingLine }) {
    state.localEditingLines = [
      ...state.localEditingLines.slice(0, value.index),
      value.line,
      ...state.localEditingLines.slice(value.index + 1),
    ];
  },
  insertLineBetween(state: SubtitleState, data: { index: number, line?: EditingLine }) {
    const newLineIndex = data.index;

    state.localEditingLines = [
      ...state.localEditingLines.slice(0, newLineIndex),
      data.line ?? {
        lineNo: newLineIndex + 1,
        text: '',
        startTime: state.localEditingLines[newLineIndex - 1].endTime,
        endTime: state.localEditingLines[newLineIndex].startTime,
        startTimeError: false,
        endTimeError: false,
      },
      ...state.localEditingLines.slice(newLineIndex),
    ];
  },
  addNewLine(state: SubtitleState, newLineIndex: number) {
    state.localEditingLines = [
      ...state.localEditingLines,
      ...getDefaultLocalEditingLines(newLineIndex),
    ];
  },
  deleteLocalEditingLine(state: SubtitleState, index: number) {
    state.localEditingLines = state.localEditingLines.toSpliced(index, 1);
  },
  resetSubtitleState(state: SubtitleState) {
    Object.assign(state, initialState());
  },
};

// action
const actions = {
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-chrome" }

import _ from 'lodash';
import { computed, onMounted } from 'vue';
import { useStore } from '@/store';
import { VideoUploadProgressPopup, type VideoUploadState } from '@/modules/videoUpload';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppChrome',
  setup(__props) {

const store = useStore<{videoUpload: VideoUploadState}>();

const videoUploadKeys = computed(() => store.state.videoUpload.videoUploadKeys);
const videoKeysGroupByState = computed(() => store.state.videoUpload.videoUploadKeys);
const isUploadingVideos = computed(() => _.get(videoUploadKeys, 'value', []).length > 0);

function getUploadVideoByStatus(statusKey: string) {
  return _.get(videoKeysGroupByState.value, statusKey, []);
}

function handleBeforeCloseBrowser(event: Event) {
  const uploadingItems = getUploadVideoByStatus('uploading');
  if (uploadingItems && uploadingItems.length > 0) {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Older browsers supported custom message
    // eslint-disable-next-line no-param-reassign
    event.returnValue = true;
    // alert('onbeforeunload');
    return 'onbeforeunload';
  }

  return undefined;
}

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeCloseBrowser);
  window.addEventListener('unload', handleBeforeCloseBrowser);
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        (isUploadingVideos.value)
          ? (_openBlock(), _createBlock(_unref(VideoUploadProgressPopup), { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { class: "alert-container" }
const _hoisted_3 = { class: "icon-container" }
const _hoisted_4 = { class: "alert-text" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "description" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "input-label" }
const _hoisted_11 = { class: "code" }

import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import type { DeleteProjectData } from '../../../types';
import { useStore } from '../../../store/hooks';

interface ComponentProps {
  deleteProjectData: DeleteProjectData;
  modelValue: string;
  onConfirmDeleteProject: () => Promise<void>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmDeletingProject',
  props: {
    deleteProjectData: {},
    modelValue: {},
    onConfirmDeleteProject: { type: Function }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { deleteProjectData, modelValue, onConfirmDeleteProject } = toRefs(props);

const emit = __emit;

const { t } = useI18n();
const store = useStore();

const projectName = computed(() => store.state.project.currentProject?.name ?? '');
const videoCount = computed(() => store.state.project.currentProject?.videoCount ?? 0);
const webhookList = computed(() => store.state.webhookList.webhookList);

const willMoveVideos = computed(() => deleteProjectData.value.actionWithVideos === 'moveVideos');
const destinationProjectName = computed(() => deleteProjectData.value.destinationProject?.name ?? '');

function onChangeInput(event: Event) {
  if (event.target instanceof HTMLInputElement) {
    emit('update:modelValue', event.target.value);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-triangle-exclamation" }, null, -1)),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)('project.deleteProject.step2.alert', { projectName: projectName.value })), 1)
      ]),
      _createElementVNode("ul", null, [
        (!willMoveVideos.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
              _createElementVNode("strong", null, _toDisplayString(videoCount.value) + " " + _toDisplayString(_unref(t)(`glossary:video${videoCount.value > 1 ? '_plural' : ''}`)), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("li", null, [
          _createElementVNode("strong", null, _toDisplayString(webhookList.value.length) + " Webhook" + _toDisplayString(webhookList.value.length > 1 ? 's' : ''), 1)
        ]),
        _createElementVNode("li", null, _toDisplayString(_unref(t)('project.deleteProject.step2.bullet3')), 1),
        _createElementVNode("li", null, _toDisplayString(_unref(t)('project.deleteProject.step2.bullet4')), 1)
      ]),
      (willMoveVideos.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_unref(t)(`project.deleteProject.step2.moveVideos${videoCount.value > 1 ? '_plural' : ''}`, {
            sourceProjectName: projectName.value,
            destinationProjectName: destinationProjectName.value
          })), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", _hoisted_7, [
      _createElementVNode("span", {
        innerHTML: _unref(t)('project.deleteProject.step2.description1')
      }, null, 8, _hoisted_8),
      _cache[1] || (_cache[1] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
      _createVNode(_component_router_link, { to: { name: 'deletedProjects' } }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('project.deleteProject.step2.description2')), 1)
        ]),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
      _createElementVNode("span", {
        innerHTML: _unref(t)('project.deleteProject.step2.description3')
      }, null, 8, _hoisted_9)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_10, [
        _createTextVNode(_toDisplayString(_unref(t)('project.deleteProject.step2.description4')) + " “", 1),
        _createElementVNode("span", _hoisted_11, "delete " + _toDisplayString(projectName.value), 1),
        _createTextVNode("” " + _toDisplayString(_unref(t)('project.deleteProject.step2.description5')), 1)
      ]),
      _createVNode(Input, {
        modelValue: _unref(modelValue),
        onChange: onChangeInput,
        onPressedEnter: _unref(onConfirmDeleteProject)
      }, null, 8, ["modelValue", "onPressedEnter"])
    ])
  ]))
}
}

})
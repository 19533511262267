import qs from 'qs';
import { axiosAPI } from '@/plugins/axios';
import { qsOption } from '@/services/constants';
import videoIssuesMockData from '@/services/api/mockData/videoIssues.json';
import type { Filter, ListApiResponse } from '@/types/Axios.type';
import type { DeletedVideo, Video } from '@/modules/shared/types/video.type';
import type { VideoIssue } from 'src/modules/videoCaption';
import { RestoreDeletedVideoToProject } from '../types/projectVideoList.type';

const defaultVideoListFilter = {
  includes: ['creator', 'vod.source'],
};

export function loadVideosAPI(filter: Filter) {
  const includes = {
    includes: [...defaultVideoListFilter.includes, !filter.projectKey && ['project']],
  };

  const query = qs.stringify({ ...includes, ...filter }, qsOption);
  return axiosAPI.get<ListApiResponse<Array<Video>>>(`/videos${query}`);
}

export function loadVideoIssues(filter: Filter) {
  const query = qs.stringify({ ...defaultVideoListFilter, ...filter }, qsOption);
  return axiosAPI.get(`/video-issues${query}`);
}

export function loadVideoCaptionIssues(filter: Filter, useMockData = false) {
  if (useMockData) {
    return {
      data: {
        total: 3,
        lastPage: 1,
        data: videoIssuesMockData,
      },
    };
  }

  const query = qs.stringify({ ...filter }, qsOption);
  return axiosAPI.get<ListApiResponse<VideoIssue[]>>(`/videos-issues/captions${query}`);
}

export function loadRecentlyDeleted(filter: Filter) {
  const query = qs.stringify({ ...filter }, qsOption);
  return axiosAPI.get<ListApiResponse<Array<DeletedVideo>>>(`/deleted-videos${query}`);
}

export function restoreDeletedVideo(videoKey: string) {
  return axiosAPI.put(`/deleted-videos/restore/${videoKey}`);
}

export function restoreDeletedProject(projectKey: string, filter?: Filter) {
  const query = qs.stringify({ ...filter }, qsOption);
  return axiosAPI.put(`/projects/${projectKey}/restore${query}`);
}

export function restoreDeletedVideoToProject(videoKey: string, payload: RestoreDeletedVideoToProject) {
  return axiosAPI.put(`/deleted-videos/restore/${videoKey}`, payload);
}

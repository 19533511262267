import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "setting-header" }
const _hoisted_2 = {
  key: 0,
  class: "preset-information"
}
const _hoisted_3 = {
  "data-test": "video-output-preset-title",
  class: "title"
}
const _hoisted_4 = { class: "profiles" }

import { ref, toRefs } from 'vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { type Preset, PresetSummaryInfo } from '@/modules/preset';
import VideoUploadDefaultBox from '../../organisms/videoUploadDefaultBox/VideoUploadDefaultBox.vue';
import VideoOutputSelector from '../videoOutputSelector/VideoOutputSelector.vue';

interface VideoOutputFieldProps {
  preset: Preset | null | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoOutputField',
  props: {
    preset: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const { preset } = toRefs(props);
const { t } = useI18n();

const isEditing = ref<boolean>(false);

const emit = __emit;

function onClickEdit() {
  isEditing.value = !isEditing.value;
}

function onChangeOutput(newPreset: Preset | null) {
  if (_.isNil(newPreset)) {
    return;
  }

  isEditing.value = false;
  emit('update:modelValue', newPreset);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VideoUploadDefaultBox, {
    label: _unref(t)('glossary:preset'),
    isEditing: isEditing.value,
    onOnClickEdit: onClickEdit
  }, {
    default: _withCtx(() => [
      (!_unref(_).isNil(_unref(preset)))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, [
              (!isEditing.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("h4", _hoisted_3, _toDisplayString(_unref(preset).name), 1),
                    _createElementVNode("div", _hoisted_4, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(preset).profiles, (profile) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "preset-profile-wrapper",
                          key: profile.id
                        }, [
                          _createVNode(_unref(PresetSummaryInfo), { profile: profile }, null, 8, ["profile"])
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            (isEditing.value)
              ? (_openBlock(), _createBlock(VideoOutputSelector, {
                  key: 0,
                  "onUpdate:modelValue": onChangeOutput,
                  presetData: _unref(preset)
                }, null, 8, ["presetData"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "isEditing"]))
}
}

})
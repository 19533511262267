import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pagination-container"
}

import SuccessToast from '@/modules/shared/components/molecules/toasts/SuccessToast.vue';
import _ from 'lodash';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import Pagination from '@/modules/shared/components/atoms/pagination/Pagination.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import DeletedVideoItem from '../components/molecules/deletedVideoItem/DeletedVideoItem.vue';
import { useStore } from '../store/hooks';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectRecentlyDeletedVideo',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const error = ref<unknown>(null);
// const videoList = ref<DeletedVideo[]>([]);
const videoList = computed(() => store.state.projectDeletedVideoList.projectDeletedVideoList);
// const isLoading = ref<boolean>(true);
const isLoading = computed(() => store.state.projectDeletedVideoList.loadProjectDeletedVideoListState.status === 'success');
const lastPage = computed(() => store.state.projectDeletedVideoList.projectDeletedVideoListLastPage); // ref<number>(1);

const getVideoFromVideosUpload = computed(() => store.getters.getVideoFromVideosUpload);
const areProjectVideosDeleted = computed(() => store.state.global.areProjectVideosDeleted);

const query = computed(() => route.query);
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));

const isEmptyRecentlyDeleted = computed(() => !isLoading.value && _.isEmpty(videoList.value));

async function load() {
  await store.dispatch('loadProjectDeletedVideoList', { filter: { ...query.value, projectKey: projectKey.value } });
}

function isShowPagination() {
  return lastPage.value > 1;
}

watch(query, () => {
  if (route.name === 'recentlyDeleted') {
    load();
  }
}, { deep: true });

onMounted(load);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (areProjectVideosDeleted.value)
      ? (_openBlock(), _createBlock(SuccessToast, {
          key: 0,
          duration: 10000,
          title: _unref(t)('project.deleteVideos.success.title'),
          description: _unref(t)('project.deleteVideos.success.description')
        }, null, 8, ["title", "description"]))
      : _createCommentVNode("", true),
    (!isLoading.value && !isEmptyRecentlyDeleted.value)
      ? (_openBlock(), _createBlock(Alert, {
          key: 1,
          alertText: _unref(t)('recentlyDeleted.info'),
          priority: "info"
        }, null, 8, ["alertText"]))
      : _createCommentVNode("", true),
    (isLoading.value)
      ? (_openBlock(), _createBlock(PreLoaderSection, { key: 2 }))
      : _createCommentVNode("", true),
    (!isLoading.value && !isEmptyRecentlyDeleted.value)
      ? (_openBlock(), _createBlock(Section, { key: 3 }, {
          "section-footer": _withCtx(() => [
            (isShowPagination())
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(Pagination, { lastPage: lastPage.value }, null, 8, ["lastPage"])
                ]))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            _createVNode(DataTable, null, {
              "table-header": _withCtx(() => [
                _createVNode(Row, null, {
                  default: _withCtx(() => [
                    _createVNode(Column, { width: "140px" }, {
                      default: _withCtx(() => [
                        _createVNode(HeaderCell)
                      ]),
                      _: 1
                    }),
                    _createVNode(Column, {
                      minWidth: "200px",
                      isPrimaryColumn: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(HeaderCell, {
                          text: _unref(t)('videoList.table.videoInfo.title')
                        }, null, 8, ["text"])
                      ]),
                      _: 1
                    }),
                    _createVNode(Column, { width: "200px" }, {
                      default: _withCtx(() => [
                        _createVNode(HeaderCell, {
                          text: _unref(t)('project.deleteVideos.table.fromProject')
                        }, null, 8, ["text"])
                      ]),
                      _: 1
                    }),
                    _createVNode(Column, { width: "200px" }, {
                      default: _withCtx(() => [
                        _createVNode(HeaderCell, {
                          text: _unref(t)('common:deletedAt')
                        }, null, 8, ["text"])
                      ]),
                      _: 1
                    }),
                    _createVNode(Column, {
                      width: "140px",
                      isActionColumn: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(HeaderCell)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              "table-content": _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(videoList.value, (video, itemIndex) => {
                  return (_openBlock(), _createBlock(DeletedVideoItem, {
                    key: itemIndex,
                    video: getVideoFromVideosUpload.value(video)
                  }, null, 8, ["video"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!isLoading.value && !error.value && isEmptyRecentlyDeleted.value)
      ? (_openBlock(), _createBlock(Section, {
          key: 4,
          class: "section-recently-deleted"
        }, {
          default: _withCtx(() => [
            _createVNode(MessageBox, {
              iconClassName: "fas fa-trash",
              title: _unref(t)('recentlyDeleted.empty.title'),
              description: _unref(t)('recentlyDeleted.empty.description')
            }, null, 8, ["title", "description"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!isLoading.value && error.value)
      ? (_openBlock(), _createBlock(Section, { key: 5 }, {
          default: _withCtx(() => [
            _createVNode(MessageBox, {
              iconClassName: "fas fa-circle-exclamation",
              title: _unref(t)('response:loadingFailed.title')
            }, null, 8, ["title"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})